<template>
  <div class="c-app flex-row align-items-center" v-bind:style="{ 'background-image': 'url(' + bgImage + ')' }">
    <CContainer>
      <CRow class="justify-content-center login-card">
        <CCol md="8" align="center">
          <img src="../../assets/img/logo_biocloudaurum.png" class="c-sidebar-brand-full"  width="240" style="margin-bottom:1rem;">
        </CCol>
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit="onSubmit">
                  <h1>Reset Password</h1>
                  <p>Reset Password your account</p>
                  <CInput
                    placeholder="Username"
                    autocomplete="username"
                    v-model="resetPass.username"
                    @input="resetPass.username=resetPass.username.toUpperCase()"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <!-- <CInput
                    placeholder="Email"
                    autocomplete="email"
                    type="email"
                    v-model="resetPass.email"
                  >
                    <template #prepend-content><CIcon name="cib-gmail"/></template>
                  </CInput> -->
                  <CInput
                    placeholder="Kode OTP"
                    autocomplete="otp"
                    v-model="resetPass.otp"
                  >
                    <template #prepend-content><CIcon name="cib-Clockify"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="resetPass.password"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CInput
                    placeholder="Confirm Password"
                    type="password"
                    v-model="resetPass.confirm_password"
                    :is-valid="validator"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <!-- <CRow>
                    <CCol col="6" class="text-left">
                      <CInput
                        v-model="inputCaptha"
                        placeholder="Verification"
                        required
                      >
                      </CInput>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="success" style="width:100%;padding:0px" v-on:click="getCaptha"><b style="font-size:22px;">{{captha}} <CIcon name="cil-reload"/></b> </CButton>
                    </CCol>
                  </CRow> -->
                  <CRow>
                    <CCol col="6" class="text-left">
                      <router-link to="/pages/login">
                      <CButton  type="submit" color="danger" class="px-4">Back</CButton>
                      </router-link>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton  type="submit" color="primary" class="px-4">Submit</CButton>
                    </CCol> 
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>

    <CModal
      title="Warning"
      color="warning"
      :show.sync="warningModal"
    >
      <p v-html="warningMsg"/>
    </CModal>

    </CContainer>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Login',
  data(){
    return{
      urlApi:this.$store.state.urlApi,
      resetPass:{
        username:'',
        // email:'',
        otp:'',
        password:'',
        confirm_password:''
      },
      changePassword:{
        password:null
      },
      clearOtp:{
        remember_otp:null
      },
      inputCaptha:null,
      warningModal: false,
      resetModal:false,
      warningMsg:null,
      captha:null,
      display:true,
      displayLoading:false,
      bgImage:'https://api-biocloudaurum.bisnisonlinebgs.com/uploads/bg-login.jpeg'
    }
  },
  mounted(){
   this.getCaptha()

    // let str = this.$route.path
    // let res = str.replace("/pages/", "");
    // let res2 = res.substring(res.indexOf("/") + 1);
    // console.log(atob(res2))
    // this.resetPass.username = atob(res2)

  },
  methods:{
    onSubmit(e){
      e.preventDefault()

      // console.log(this.resetPass)

      this.changePassword.password = this.resetPass.password

      axios.get(`${this.urlApi}/api/user/getUserByUsernameData/${this.resetPass.username}`).then(response => {
        if(response.data.status == 0){
          this.warningModal = true
          this.warningMsg = "username tidak ditemukan. harap hubungi cs kami <br > username not found. please contact our cs"
        } else {
          // console.log(response.data)

          if(this.resetPass.password == this.resetPass.confirm_password && this.resetPass.otp == response.data.remember_otp){

            this.clearOtp.remember_otp = '0'
            axios.put(`${this.urlApi}/api/user-details/${response.data.user_id}`,this.clearOtp).then(responseClearOtp => {
              axios.put(`${this.urlApi}/api/reset-password/update/${response.data.user_id}`,this.changePassword).then(response => {
                location.href = ('/#/pages/login')
              })
            })
            
          } else {
            this.warningModal = true
            this.warningMsg = "harap periksa kembali inputan anda <br > please check again"
          }

        }
       })


    },
    getCaptha(){
      let char = "1234567890";
      let lenString = 5;
	    let randomstring = '';

      for (var i=0; i<lenString; i++) {
        let rnum = Math.floor(Math.random() * char.length);
        randomstring += char.substring(rnum, rnum+1);
      }

      this.captha = randomstring
    },
    validator (val) {
      return val ? val == this.resetPass.password : false
    }


  }
}
</script>